import {EuiInMemoryTable, EuiBadge} from '@elastic/eui/es';
import PropTypes from 'prop-types'

const Table = (props) => {
    const {
        data
    } = props
    const columns = [
        {
            field: 'volunteerName',
            name: 'Volunteer Name',
            sortable: true,
            truncateText: false,
        },
        {
            field: 'mohallaName',
            name: 'Mohalla',
            sortable: true,
            truncateText: false,
        },
        {
            field: 'ward',
            name: 'Ward',
            sortable: true,
            truncateText: false,
        },
        {
            field: 'status',
            name: 'Current Status',
            truncateText: false,
            sortable: true,
            render: (status) => {
                const color = status ? (status.toLowerCase() === 'resolved' ? '#BADA55' : 'accent') : 'default'
                return (<EuiBadge color={color}>{status ? status: 'No status available'}</EuiBadge>)
            }
        },
        {
            field: 'classificationOfWork',
            name: 'Classification of work',
            truncateText: false,
            sortable: true,
        },
    ];

    const sorting = {
        sort: {
            field: 'volunteerName',
            direction: 'asc',
        },
    };

    return (
        <EuiInMemoryTable
            responsive={false}
            items={data}
            columns={columns}
            pagination={false}
            sortable={sorting}
        />
    );
};

Table.propTypes = {
    data: PropTypes.array.isRequired,
}

export {Table}