import React, {useEffect, useState} from 'react';
import {loadGapiInsideDOM} from 'gapi-script';
import './GoogleLogin.css';
import {Table} from "./Table";
import {EuiButton} from "@elastic/eui/es";

const camelCase = require('lodash.camelcase')

let gapi = ''

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";

export const GoogleLogin = () => {
    const [user, setUser] = useState({});
    const [data, setData] = useState([])

    const initClient = () => {
        gapi.client.init({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        }).then(function () {
            // Listen for sign-in state changes.
            gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
            // Handle the initial sign-in state.
            updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        }, function (error) {
        });
    }

    useEffect(() => {
        // handle client load
        (async () => {
            gapi = await loadGapiInsideDOM();
            gapi.load('client:auth2', initClient);
        })()
    }, [])

    useEffect(() => {
        console.log(data)
    }, [data])

    function updateSigninStatus(isSignedIn) {
        if (isSignedIn) {
            gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: process.env.REACT_APP_GOOGLE_SHEET_ID,
                range: "Date wise database",
            }).then(({result}) => {
                const {
                    values
                } = result
                const headerRow = values.shift()
                setData(values.map((row) => {
                    let obj = {}
                    row.forEach((ele, index) => {
                        obj[camelCase(headerRow[index])] = ele
                    })
                    return obj
                }))
            })
            updateUser(gapi.auth2.getAuthInstance().currentUser.get())
        }
    }

    /**
     *  Sign in the user upon button click.
     */
    function handleAuthClick(event) {
        gapi.auth2.getAuthInstance().signIn();
    }

    /**
     *  Sign out the user upon button click.
     */
    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
            setUser({});
            console.log('User signed out.');
        });
    }

    const updateUser = (currentUser) => {
        const name = currentUser.getBasicProfile().getName();
        const profileImg = currentUser.getBasicProfile().getImageUrl();
        setUser({
            name: name,
            profileImg: profileImg,
        });
    };

    return (
        <div className="container">
            {!Object.keys(user).length &&
            (<EuiButton onClick={handleAuthClick} id="customBtn">Authorize</EuiButton>)
            }
            {!!Object.keys(user).length &&
            <>
                <EuiButton onClick={signOut} id="customBtn">Sign Out</EuiButton>
                <Table data={data}/>
            </>
            }
        </div>
    );
}
